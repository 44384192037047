import { default as archivexSxWXXz0vOMeta } from "/app/polystore/client/pages/archive.vue?macro=true";
import { default as change_45passwordx9Lnct5oUUMeta } from "/app/polystore/client/pages/change-password.vue?macro=true";
import { default as _91fileId_93XoY9bZsbruMeta } from "/app/polystore/client/pages/file/[fileId].vue?macro=true";
import { default as _91fileId_9336jtTrvm5BMeta } from "/app/polystore/client/pages/folder/[fileId].vue?macro=true";
import { default as forgot_45passwordr1h8LqlOEoMeta } from "/app/polystore/client/pages/forgot-password.vue?macro=true";
import { default as homeSj23WnweQuMeta } from "/app/polystore/client/pages/home.vue?macro=true";
import { default as index8WOVuWpq0BMeta } from "/app/polystore/client/pages/index.vue?macro=true";
import { default as join_45discordI5dKswIP9UMeta } from "/app/polystore/client/pages/join-discord.vue?macro=true";
import { default as legalRqOsYPJk3SMeta } from "/app/polystore/client/pages/legal.vue?macro=true";
import { default as loginNejb2rkQzYMeta } from "/app/polystore/client/pages/login.vue?macro=true";
import { default as onboarding5TddkLuKtQMeta } from "/app/polystore/client/pages/onboarding.vue?macro=true";
import { default as _91searchId_93m00BZX2RTcMeta } from "/app/polystore/client/pages/search/[searchId].vue?macro=true";
import { default as indexGEVyxpk94yMeta } from "/app/polystore/client/pages/settings/index.vue?macro=true";
import { default as _91handle_93sGxsiw61ArMeta } from "/app/polystore/client/pages/shared/[handle].vue?macro=true";
import { default as signupKCgkz9UyHPMeta } from "/app/polystore/client/pages/signup.vue?macro=true";
import { default as verifydppyMXm1QKMeta } from "/app/polystore/client/pages/verify.vue?macro=true";
export default [
  {
    name: archivexSxWXXz0vOMeta?.name ?? "archive",
    path: archivexSxWXXz0vOMeta?.path ?? "/archive",
    meta: archivexSxWXXz0vOMeta || {},
    alias: archivexSxWXXz0vOMeta?.alias || [],
    redirect: archivexSxWXXz0vOMeta?.redirect,
    component: () => import("/app/polystore/client/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordx9Lnct5oUUMeta?.name ?? "change-password",
    path: change_45passwordx9Lnct5oUUMeta?.path ?? "/change-password",
    meta: change_45passwordx9Lnct5oUUMeta || {},
    alias: change_45passwordx9Lnct5oUUMeta?.alias || [],
    redirect: change_45passwordx9Lnct5oUUMeta?.redirect,
    component: () => import("/app/polystore/client/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: _91fileId_93XoY9bZsbruMeta?.name ?? "file-fileId",
    path: _91fileId_93XoY9bZsbruMeta?.path ?? "/file/:fileId()",
    meta: _91fileId_93XoY9bZsbruMeta || {},
    alias: _91fileId_93XoY9bZsbruMeta?.alias || [],
    redirect: _91fileId_93XoY9bZsbruMeta?.redirect,
    component: () => import("/app/polystore/client/pages/file/[fileId].vue").then(m => m.default || m)
  },
  {
    name: _91fileId_9336jtTrvm5BMeta?.name ?? "folder-fileId",
    path: _91fileId_9336jtTrvm5BMeta?.path ?? "/folder/:fileId()",
    meta: _91fileId_9336jtTrvm5BMeta || {},
    alias: _91fileId_9336jtTrvm5BMeta?.alias || [],
    redirect: _91fileId_9336jtTrvm5BMeta?.redirect,
    component: () => import("/app/polystore/client/pages/folder/[fileId].vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordr1h8LqlOEoMeta?.name ?? "forgot-password",
    path: forgot_45passwordr1h8LqlOEoMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordr1h8LqlOEoMeta || {},
    alias: forgot_45passwordr1h8LqlOEoMeta?.alias || [],
    redirect: forgot_45passwordr1h8LqlOEoMeta?.redirect,
    component: () => import("/app/polystore/client/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: homeSj23WnweQuMeta?.name ?? "home",
    path: homeSj23WnweQuMeta?.path ?? "/home",
    meta: homeSj23WnweQuMeta || {},
    alias: homeSj23WnweQuMeta?.alias || [],
    redirect: homeSj23WnweQuMeta?.redirect,
    component: () => import("/app/polystore/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: index8WOVuWpq0BMeta?.name ?? "index",
    path: index8WOVuWpq0BMeta?.path ?? "/",
    meta: index8WOVuWpq0BMeta || {},
    alias: index8WOVuWpq0BMeta?.alias || [],
    redirect: index8WOVuWpq0BMeta?.redirect,
    component: () => import("/app/polystore/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: join_45discordI5dKswIP9UMeta?.name ?? "join-discord",
    path: join_45discordI5dKswIP9UMeta?.path ?? "/join-discord",
    meta: join_45discordI5dKswIP9UMeta || {},
    alias: join_45discordI5dKswIP9UMeta?.alias || [],
    redirect: join_45discordI5dKswIP9UMeta?.redirect,
    component: () => import("/app/polystore/client/pages/join-discord.vue").then(m => m.default || m)
  },
  {
    name: legalRqOsYPJk3SMeta?.name ?? "legal",
    path: legalRqOsYPJk3SMeta?.path ?? "/legal",
    meta: legalRqOsYPJk3SMeta || {},
    alias: legalRqOsYPJk3SMeta?.alias || [],
    redirect: legalRqOsYPJk3SMeta?.redirect,
    component: () => import("/app/polystore/client/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: loginNejb2rkQzYMeta?.name ?? "login",
    path: loginNejb2rkQzYMeta?.path ?? "/login",
    meta: loginNejb2rkQzYMeta || {},
    alias: loginNejb2rkQzYMeta?.alias || [],
    redirect: loginNejb2rkQzYMeta?.redirect,
    component: () => import("/app/polystore/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: onboarding5TddkLuKtQMeta?.name ?? "onboarding",
    path: onboarding5TddkLuKtQMeta?.path ?? "/onboarding",
    meta: onboarding5TddkLuKtQMeta || {},
    alias: onboarding5TddkLuKtQMeta?.alias || [],
    redirect: onboarding5TddkLuKtQMeta?.redirect,
    component: () => import("/app/polystore/client/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91searchId_93m00BZX2RTcMeta?.name ?? "search-searchId",
    path: _91searchId_93m00BZX2RTcMeta?.path ?? "/search/:searchId()",
    meta: _91searchId_93m00BZX2RTcMeta || {},
    alias: _91searchId_93m00BZX2RTcMeta?.alias || [],
    redirect: _91searchId_93m00BZX2RTcMeta?.redirect,
    component: () => import("/app/polystore/client/pages/search/[searchId].vue").then(m => m.default || m)
  },
  {
    name: indexGEVyxpk94yMeta?.name ?? "settings",
    path: indexGEVyxpk94yMeta?.path ?? "/settings",
    meta: indexGEVyxpk94yMeta || {},
    alias: indexGEVyxpk94yMeta?.alias || [],
    redirect: indexGEVyxpk94yMeta?.redirect,
    component: () => import("/app/polystore/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93sGxsiw61ArMeta?.name ?? "shared-handle",
    path: _91handle_93sGxsiw61ArMeta?.path ?? "/shared/:handle()",
    meta: _91handle_93sGxsiw61ArMeta || {},
    alias: _91handle_93sGxsiw61ArMeta?.alias || [],
    redirect: _91handle_93sGxsiw61ArMeta?.redirect,
    component: () => import("/app/polystore/client/pages/shared/[handle].vue").then(m => m.default || m)
  },
  {
    name: signupKCgkz9UyHPMeta?.name ?? "signup",
    path: signupKCgkz9UyHPMeta?.path ?? "/signup",
    meta: signupKCgkz9UyHPMeta || {},
    alias: signupKCgkz9UyHPMeta?.alias || [],
    redirect: signupKCgkz9UyHPMeta?.redirect,
    component: () => import("/app/polystore/client/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: verifydppyMXm1QKMeta?.name ?? "verify",
    path: verifydppyMXm1QKMeta?.path ?? "/verify",
    meta: verifydppyMXm1QKMeta || {},
    alias: verifydppyMXm1QKMeta?.alias || [],
    redirect: verifydppyMXm1QKMeta?.redirect,
    component: () => import("/app/polystore/client/pages/verify.vue").then(m => m.default || m)
  }
]